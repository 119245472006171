import React, {FC} from "react";
import {ID} from "../../../../../_metronic/helpers";
import {useQuery} from "react-query";
import {
    GetTechnicalAdvisorsForInterview,
    JobPositionDto,
    JobPositionService,
    LineOfBusinessDto,
    TechnicalAdvisorAcceptanceSkillDto,
    TechnicalAdvisorDto,
    TechnicalAdvisorSAcceptanceSkillService
} from "../../../../../services/requests";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useIntl} from "react-intl";

type Props = {
    technicalAdvisor: TechnicalAdvisorDto
    lineOfBusiness: LineOfBusinessDto
    jobPositions: JobPositionDto[]
    activeAcceptanceSkills: TechnicalAdvisorAcceptanceSkillDto[]
}

const editAcceptanceSkillsSchema = Yup.object().shape({
    acceptanceSkills: Yup.array()
        .min(1)
})

type AcceptanceSkills = {
    jobPosition: string
    levelOfExpertise: string[]
}
const AcceptanceSkills: FC<Props> = ({technicalAdvisor, lineOfBusiness, activeAcceptanceSkills, jobPositions}) => {

    const intl = useIntl()

    const acceptanceSkillsForForm: string[] = [];
    if(Array.isArray(activeAcceptanceSkills)) {
        activeAcceptanceSkills?.forEach(as => {
            const jP = as.jobPosition;
            as.levelsOfExpertise?.forEach(loe => acceptanceSkillsForForm[acceptanceSkillsForForm.length] = jP + '/' + loe);
        });
    }

    const formikAcceptanceSkills = useFormik({
        initialValues: {acceptanceSkills: acceptanceSkillsForForm},
        validationSchema: editAcceptanceSkillsSchema,
        onSubmit: async (values) => {

            let newAcceptanceSkills = values.acceptanceSkills.map(as => {
                let asSplit = as.split("/");
                return {id: asSplit[0], levelOfExpertise: asSplit[1] }
            }).reduce((list, { id, levelOfExpertise }) => {
                (list[id] = list[id] || []).push(levelOfExpertise);
                return list;
            }, {});

            jobPositions?.map(async jobPosition => {
                const currentKey = Object.keys(newAcceptanceSkills).find(elt => elt == jobPosition.id);
                const activeAcceptanceSkillToWorkOn = activeAcceptanceSkills?.find(elt => elt.jobPosition === jobPosition.id);
                if(!!currentKey) {
                    if(!!activeAcceptanceSkillToWorkOn) {

                        let updateNeeded = activeAcceptanceSkillToWorkOn.levelsOfExpertise.length != newAcceptanceSkills[currentKey].length;
                        if(!updateNeeded) {
                           updateNeeded = activeAcceptanceSkillToWorkOn.levelsOfExpertise.filter(loe => !newAcceptanceSkills[currentKey].find(newLoe => newLoe == loe)).length > 0
                                            || newAcceptanceSkills[currentKey].filter(loe => !activeAcceptanceSkillToWorkOn.levelsOfExpertise.find(newLoe => newLoe == loe)).length > 0
                        }
                        if(updateNeeded) {
                            await TechnicalAdvisorSAcceptanceSkillService.update3(activeAcceptanceSkillToWorkOn.id, {
                                id: activeAcceptanceSkillToWorkOn.id,
                                technicalAdvisor: technicalAdvisor.id as string,
                                jobPosition: jobPosition.id as string,
                                levelsOfExpertise: newAcceptanceSkills[currentKey]
                            })
                        }
                    } else {
                        console.log('Perform creation of : '+ jobPosition.id);
                        await TechnicalAdvisorSAcceptanceSkillService.create3({
                            technicalAdvisor: technicalAdvisor.id as string,
                            jobPosition: jobPosition.id as string,
                            levelsOfExpertise: newAcceptanceSkills[currentKey]
                        });
                    }
                } else {
                    if(!!activeAcceptanceSkillToWorkOn) {
                        await TechnicalAdvisorSAcceptanceSkillService.delete(activeAcceptanceSkillToWorkOn.id);
                    }
                }
            });
        },
    })

    return (
        <>
            {!!jobPositions && jobPositions.length > 0 && (
                <form id='technical-advisor-acceptance-skills' className='form' onSubmit={formikAcceptanceSkills.handleSubmit} noValidate>
                    {jobPositions.map(jobPosition => (
                        <div className='fv-row mb-15'>
                            <label className='fw-bold fs-6 mb-2'>{jobPosition.name}</label>

                            <div>
                                {Object.keys(GetTechnicalAdvisorsForInterview.levelOfExpertise)?.map((levelOfExpertise) => (
                                    <label className='form-check form-check-inline form-check-solid me-5'>
                                        <input
                                            {...formikAcceptanceSkills.getFieldProps('acceptanceSkills')}
                                            defaultChecked={!!acceptanceSkillsForForm.find(as => as == (jobPosition.id + '/' + levelOfExpertise))}
                                            className='form-check-input'
                                            name='acceptanceSkills'
                                            data-cy='acceptanceSkills'
                                            type='checkbox'
                                            value={jobPosition.id + '/' + levelOfExpertise}
                                        />
                                        <span className='fw-bold ps-2 fs-6'>{levelOfExpertise}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    ))}
                    {formikAcceptanceSkills.touched.acceptanceSkills && formikAcceptanceSkills.errors.acceptanceSkills && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formikAcceptanceSkills.errors.acceptanceSkills}</span>
                            </div>
                        </div>
                    )}
                    <div className='text-center pt-15'>
                        <button
                            type='submit'
                            className='btn btn-primary me-3'
                        >
                            {intl.formatMessage({id: 'FORM.TECHNICAL_ADVISOR_EDIT.SAVE_ACCEPTANCE_SKILLS'})}
                        </button>
                    </div>
                </form>
            )}
        </>
    );
}


export {AcceptanceSkills};