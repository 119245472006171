import Select from "react-select";

export const CustomSelect = (props) => {
    return (
        <div data-cy={props['data-cy']}>
            <Select
                {...props}
                classNamePrefix="custom-select"
            />
        </div>
    );
};
